document.addEventListener("turbo:load", () => {
    //-----------------------------------------------------------------------------------------------------------------
    // Autocomplete search

    var sid = document.getElementById("searchid")

    function c(t) { console.log(t) }

    function get_selected_value_type(value) {
        for (let i = 0; i < tags.length; i++) {
            if (tags[i]["name"] == value) {
                return tags[i]["type"]
            }
        }
    }

    function change_search_url() {

        var searchInput = document.getElementById("myInput");

        if (searchInput.value == "" || searchInput.value == " ") {
            return
        }

        var form = document.getElementById("search_form")

        var route = "#"

        var s_value = searchInput.value

        var type = get_selected_value_type(s_value)

        // console.log("zzd")

        if (type === "tag") {
            route = "/" + s_value + "-jobs"
        } else if (type === "city") {
            route = "/web3-jobs-" + s_value
        } else if (type === "country") {
            route = "/web3-jobs-" + s_value
        } else if (type === "company") {
            route = "/web3-companies/" + s_value
        } else {
            route = null
        }

        // console.log("type: " + type)
        // console.log("type: " + s_value)


        if (route === null) {
            searchInput.disabled = true
            // searchInput.classList.remove("border-primary");
        } else {
            // window.location.pathname = route
            Turbo.visit(route)
        }

        // form.action = route
        // searchInput.disabled = true


        // c("type: " + type)
        // c("selected value: " + s_value)
        // c("route: " + route)


    }

    /* SA 2021-12-30; 927d; v8) */

    function create_search_div(el, a,b,val, inp) {
      /*create a DIV element for each matching element:*/
      b = document.createElement("DIV");
      /*make the matching letters bold:*/
      //b.innerHTML = "<strong>"  + el["name"].substr(0, val.length) + "</strong>";
      b.innerHTML += "<span class='d-flex justify-content-between'>" + el["name"] + "<span style='color: grey; font-size: 12px;'>" + el["type"] + "</span></span>"
      /*insert a input field that will hold the current array item's value:*/
      b.innerHTML += "<input type='hidden' value='" + el["name"] + "'>";
      /*execute a function when someone clicks on the item value (DIV element):*/
      b.addEventListener("click", function(e) {
          /*insert the value for the autocomplete text field:*/
          inp.value = this.getElementsByTagName("input")[0].value;
          console.log(inp.value)
          change_search_url()
          /*close the list of autocompleted values,
          (or any other open lists of autocompleted values:*/
          
      });
      a.appendChild(b);
    }


    function autocomplete(inp, arr) {


        var onlyFocused = false;
        
        front_tags = [{name: "backend", type: "tag"}, {name: "community-manager", type: "tag"}, {name: "customer-support", type: "tag"}, {name: "design", type: "tag"}, {name: "front-end", type: "tag"}, {name: "full-stack", type: "tag"}, {name: "intern", type: "tag"}, {name: "marketing", type: "tag"}, {name: "mobile", type: "tag"}, {name: "non-tech", type: "tag"}, {name: "product-manager", type: "tag"}, {name: "sales",type: "tag"}]

        all_tags = arr

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("focus", function(e) {
            
            onlyFocused = true;

            var a, b, i, val = this.value;
            
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);

            arr = front_tags

            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/

                var el = arr[i]
                create_search_div(el, a,b,val, inp)
            }
        })

        inp.addEventListener("input", function(e) {

            var a, b, i, val = this.value;
            onlyFocused = false;
            
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) {
              
              onlyFocused = true;
              
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/

            if (onlyFocused) {
              arr = front_tags
            } else{
              arr = all_tags
            }

            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/

                var el = arr[i]

                if (onlyFocused) {
                  create_search_div(el, a,b,val, inp)
                  
                } else {
                  if (el["name"].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                     create_search_div(el, a,b,val, inp)
                  }
                }
            }

            if(document.getElementById("myInputautocomplete-list").childElementCount < 1) {
              inp.style.color = "red"
            } else {
              inp.style.color = "white"
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function(e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }

        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }

        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function(e) {
            closeAllLists(e.target);
        });
    }


    if (document.getElementById("myInput") != null) {
        tags = search_tags

        autocomplete(document.getElementById("myInput"), tags);

    }

})